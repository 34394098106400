import CassetteRow from 'pages/Project/Home/CassettesRow/CassetesRow';
import { useEffect, useState } from 'react';
import PhotosGallery from 'pages/Project/Home/Gallery/Index';
import { useSelector } from 'react-redux';
import './style.css';
import { Television } from './Television';
import { Switch } from 'antd'; 
import SearchOptions from 'pages/Project/Home/OptionsPanel/Search';


const VideoStudio = ({
  members,
  isMobile,
  companions,
  showCassetteRow,
  videos
}) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [narrationData, setNarrationData] = useState([]);
  const [subtitleText, setSubtitleText] = useState([]);
  const [showCassette, setShowCassette] = useState(true); 
  const project = useSelector(state => state.projectManager.project);

  const selectedVideo = useSelector(state => state.videoWatcher.selectedVideo);
  const videoTime = useSelector(state => state.videoWatcher.videoTime);
  const isAdmin = project?.role === 'admin';

  useEffect(() => {
    const matchingNarrations = narrationData.filter(
      narration =>
        currentTime >= narration.start_time && currentTime <= narration.end_time
    );
    setSubtitleText(matchingNarrations);
  }, [currentTime, narrationData]);

  if (isMobile) {
    return (
      <div
        className="tv"
        style={{ flexDirection: 'column', alignItems: 'center'}}>
        <Television
          videoSrc={selectedVideo === undefined ? '' : selectedVideo.url}
          isMobile={isMobile}
        />
        <Switch
          checked={showCassette}
          onChange={checked => setShowCassette(checked)}
          checkedChildren="Gallery"
          unCheckedChildren="Tapes"
          style={{ margin: '5px' }}
        />
        <div className={`cassette-container-mobile`} id="portal-root">
        <SearchOptions project={project} />

          {showCassette ? (
            <PhotosGallery isMobile={isMobile} videos={videos} style={{width: "80%"}} isAdmin={isAdmin}/>
          ) : (
            <CassetteRow videos={videos} isAdmin={isAdmin} />
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="tv" style={{ flexDirection: 'row' }}>
        <div style={{ width: 'row',  maxHeight: "800px", overflowY: "scroll", scrollBehavior: "smooth" }}>
          <Switch
            checked={showCassette}
            onChange={checked => setShowCassette(checked)}
            checkedChildren="Gallery"
            unCheckedChildren="Tapes"
            style={{ margin: '5px' }}

          />
                <SearchOptions project={project} />

          {showCassette ? (
        
        <div className="cassette-container" id="portal-root">
        <PhotosGallery videos={videos}    />
      </div>
          ) : (
            <div className="cassette-container" id="portal-root">
            <CassetteRow videos={videos} mobileScreen={isMobile} />
          </div>
          )}
        </div>

        <Television
          videoSrc={selectedVideo === undefined ? '' : selectedVideo.url}
          isMobile={isMobile}
        />
      </div>
    );
  }
};

export default VideoStudio;
