/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Col, Row, Button, Dropdown, Menu } from 'antd';
import { useContext, useState, useEffect } from 'react';
import { UserContext } from 'components';
import { useDispatch } from 'react-redux';
import { setScenes } from 'store/reducers/watchReducer';
import { getProjectScenes, getProjectVideos } from 'utils/hooks';
import styled from '@emotion/styled';
import {
  DownOutlined,
} from '@ant-design/icons';
import { ReactComponent as Birthday } from './Icons/Birthday.svg';
import { ReactComponent as Holiday } from './Icons/Holiday.svg';
import { ReactComponent as Month } from './Icons/Month.svg';
import { ReactComponent as Year } from './Icons/Year.svg';
import { ReactComponent as Text } from './Icons/Text.svg';

import { Tooltip } from 'antd'

const tagStyles = css`
  display: inline-block;
  padding: 4px 8px;
  margin-right: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d0d0d0;
  }
`;

const StyledMentionsInput = styled.input`
position: relative;
  padding: 7.5px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  &:focus {
    border-color: #007bff; /* Highlight border on focus */
  }
`;

const SearchOptions = ({ project }) => {
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState('');
  const [activeOption, setActiveOption] = useState('birthday');
  const [filters, setFilters] = useState({
    birthday: null,
    month: null,
    year: null,
    holiday: null,
    day: null,
    text: null
  });

  const dropdownData = {
    month: [
      { id: '1', display: 'January' },
      { id: '2', display: 'February' },
      { id: '3', display: 'March' },
      { id: '4', display: 'April' },
      { id: '5', display: 'May' },
      { id: '6', display: 'June' },
      { id: '7', display: 'July' },
      { id: '8', display: 'August' },
      { id: '9', display: 'September' },
      { id: '10', display: 'October' },
      { id: '11', display: 'November' },
      { id: '12', display: 'December' }
    ],
    year: Array.from({ length: 2010 - 1980 + 1 }, (_, index) => {
      const year = (1980 + index).toString();
      return { id: year, display: year };
    }),
    holiday: [
      { id: '1', display: 'Chanukah' },
      { id: '2', display: 'Purim' },
      { id: '3', display: 'Passover' },
      { id: '4', display: 'Shavuot' },
      { id: '5', display: 'Rosh Hashanah' },
      { id: '6', display: 'Yom Kippur' },
      { id: '7', display: 'Sukkot' },
      { id: '8', display: 'Simchat Torah' }
    ],
    // day: Array.from({ length: 31 }, (_, index) => ({
    //   id: (index + 1).toString(),
    //   display: (index + 1).toString()
    // })),
    birthday: project.members
      .filter(member => member.birthday)
      .map(member => ({
        id: member.id,
        display: `${member.name} - ${member.birthday}`
      }))
  };

  const cycleIcons = {
    birthday: <Birthday style={{ margin: '5px' }} />,
    holiday: <Holiday style={{ margin: '5px' }} />,
    text: <Text style={{ margin: '5px' }} />,
    month: <Month style={{ margin: '5px' }} />,
    year: <Year style={{ margin: '5px' }} />,
    // day: <CalendarOutlined />,
  };

  const handleCycle = () => {
    const options = ['text', ...Object.keys(dropdownData)];
    const currentIndex = options.indexOf(activeOption);
    const nextOption = options[(currentIndex + 1) % options.length];
    setActiveOption(nextOption);
  };

  const handleSelect = key => {
    const selectedOption = dropdownData[activeOption].find(
      item => String(item.id) === String(key)
    );
    setFilters(prev => ({
      ...prev,
      [activeOption]: selectedOption
    }));
  };

  const handleSearch = () => {
    
    const { year, month, day, holiday, birthday, text } = filters;
    getProjectScenes(
      user,
      project.id,
      text,
      year?.id,
      month?.id,
      day?.id,
      birthday?.id,
      holiday?.id
    )
      .then(response => {
        dispatch(setScenes(response.data.scenes));
      })
      .catch(console.error);
  };

  useEffect(() => {
    handleSearch();
  }, [filters, searchInput]);

  const dropdownMenu = (
    <Menu onClick={({ key }) => handleSelect(key)}>
      {dropdownData[activeOption]?.map(option => (
        <Menu.Item key={option.id}>{option.display}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <Row justify="center" align="middle">
        {/* Main Row for Centered Content */}
        <Row
          justify="center"
          align="middle"
          style={{
            width: '80%',
            display: 'flex',
          }}
        >
          <Col
            style={{
              display: 'flex', 
              justifyContent: 'center',
              alignItems: 'center', 
              gap: '10px', 
            }}
          >
            <Tooltip title={activeOption.charAt(0).toUpperCase() + activeOption.slice(1)}>
              <Button
                onClick={handleCycle}
                style={{
                  height: '40px', 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  padding: '2px', 
                }}
              >
                {cycleIcons[activeOption]}
              </Button>
            </Tooltip>
              {activeOption !== 'text' && (
              <Dropdown overlay={dropdownMenu} trigger={['click']}>
                <Button
                  style={{
                    minWidth: '180px', 
                    height: '40px', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                  }}
                >
                  Select {activeOption} <DownOutlined />
                </Button>
              </Dropdown>
            )}
            {activeOption === 'text' && (
              <StyledMentionsInput
                style={{
                  minWidth: '180px',
                  height: '40px', 
                  textAlign: 'center',
                  padding: '8px',
                }}
                placeholder="Enter free text"
                value={filters.text || ''}
                onChange={(e) =>
                  setFilters((prev) => ({
                    ...prev,
                    text: e.target.value,
                  }))
                }
              />
            )}
          </Col>
        </Row>
      </Row>  
      <Row justify="center" align="middle" style={{ margin: '3px' }}>
        <Col span={24}>
          {Object.entries(filters)
            .filter(([, value]) => value)
            .map(([key, value]) => (
              <div
                css={tagStyles}
                key={key}
                onClick={() => setFilters(prev => ({ ...prev, [key]: null }))}
              >
                {key.charAt(0).toUpperCase() + key.slice(1)}: {value.display || value}
              </div>
            ))}
        </Col>
      </Row>
    </>
  );
  
};

export default SearchOptions;
