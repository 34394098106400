import React, { useCallback } from "react";
import { Gallery } from "react-grid-gallery";
import { setSelectedVideo, setVideoTime } from "store/reducers/watchReducer";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from 'antd';

const PhotosGallery = ({ isMobile, videos, isAdmin }) => {
    const dispatch = useDispatch();
    const { Title, Text } = Typography;

  const photos = useSelector((state) => state.videoWatcher.scenes);

  // Group photos by year
  const photosByYear = photos.reduce((groups, photo) => {
    const { scene_year } = photo;
    if (!groups[scene_year]) {
      groups[scene_year] = [];
    }
    groups[scene_year].push(photo);
    return groups;
  }, {});


  const handleClick = useCallback(
    (index, item) => {
      if (item.scene_start_time && videos[item.video]) {
        dispatch(setVideoTime(item.scene_start_time));
        dispatch(setSelectedVideo(videos[item.video]));
      } else {
        console.warn("Photo or video data is missing");
      }
    },
    [dispatch, videos]
  );


  return (
    <div style={{ width: '100%', maxHeight:  isMobile?  "50vh" : "90vh",    overflowY: "scroll", scrollBehavior: "smooth" }}>
    {Object.keys(photosByYear).map((scene_year) => (
        <div key={scene_year}>
  <div style={{ textAlign: "center", marginBottom: "2px" }}>
    <Title level={3} style={{  margin: "2px" }}>
      {scene_year !== "null" ? scene_year : "Unknown"}
    </Title>
  </div>
          <Gallery
            images={photosByYear[scene_year]}
            onClick={handleClick}
            enableImageSelection={false}
            rowHeight={isMobile? 150: 100}
          />
        </div>
      ))}
    </div>
  );
};

export default PhotosGallery;
